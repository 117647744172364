<template>
  <div class="">
    <div class="c-page-description__title-container">
      <div class="c-reach-out__title">
        <h2>If you are representing a</h2>
        <h1 class="c-reach-out__title-text">Financial Institution or an Accredited Client</h1>
      </div>
      <div class="c-reach-out__description">
        <p class="c-reach-out__description-text">
          Do you need a digital assets strategy? Our team would love to help you get ahead of the competition.
        </p>
      </div>
    </div>
    <div class="c-reach-out">
      <div class="c-reach-out__body">
        <div class="c-reach-out__body-form">
            <input class="c-reach-out__body-form-input" v-model="name" placeholder="Full Name"/>
            <input class="c-reach-out__body-form-input" v-model="company" placeholder="Company"/>
            <input class="c-reach-out__body-form-input" v-model="contactNumber" placeholder="Contact Number" @keypress="isNumber($event)"/>
            <input class="c-reach-out__body-form-input" v-model="email" placeholder="Email" @input="debouncedEmailCheck"/>
            <div class="c-reach-out__body-form-checkbox-wrapper">
              <div class="c-reach-out__body-form-checkbox" :class="{'c-reach-out__body-form-checkbox_blue': termChecked}" @click="clickTerm()">
                <div class="c-reach-out__body-form-checkbox-checkmark" v-if="termChecked"></div>
              </div>
              <label class="c-reach-out__body-form-checkbox-text">I have read and agreed to terms in the <span class="c-reach-out__body-form-checkbox-text_underline" @click="openLink('/legal/privacy-policies')">Privacy Policy</span></label>
            </div>
            <div class="c-reach-out__body-form-checkbox-wrapper">
              <div class="c-reach-out__body-form-checkbox" :class="{'c-reach-out__body-form-checkbox_blue': informationChecked}"  @click="clickInformation()">
                <div class="c-reach-out__body-form-checkbox-checkmark" v-if="informationChecked"></div>
              </div>
              <label class="c-reach-out__body-form-checkbox-text">Please send me information and news updates from Sparrow</label>
            </div>
            <div class="c-reach-out__body-form-button">
              <button class="c-reach-out__body-form-button-submit" :class="{'c-reach-out__body-form-button-submit_disable': !termChecked || !checkFormValue}" @click="submitData">Submit</button>
            </div>
          </div>
      </div>
      <div class="c-reach-out__title c-reach-out__title_longer-margin">
        <h1 class="c-reach-out__title-text">If you are an individual subscriber</h1>
      </div>
      <div class="c-reach-out__investor" v-if="screenWidth > 428">
        <div class="c-reach-out__investor-list" v-for="(dt,index) in questionList" :key="index" @click="openLink(dt.link)">
          <h3 class="c-reach-out__investor-list-box">
            <span class="c-reach-out__investor-list-box-text_bold">{{dt.text}}</span>
            <div class="c-reach-out__investor-list-box-arrow">
              <span class="c-reach-out__investor-list-box-arrow-text">></span>
            </div>
          </h3>
        </div>
      </div>

      <div class="c-reach-out__investor" v-else>
        <div class="c-reach-out__investor-list" v-for="(dt,index) in questionList" :key="index" @click="openLink(dt.link)">
          <h3 class="c-reach-out__investor-list-box">
            <div class="c-reach-out__investor-list-box-text_bold">{{dt.text}}
              <span class="c-reach-out__investor-list-box-arrow">
                <span class="c-reach-out__investor-list-box-arrow-text">></span>
              </span>
            </div>
            <!-- <div class="c-reach-out__investor-list-box-arrow">
              <span class="c-reach-out__investor-list-box-arrow-text">></span>
            </div> -->
          </h3>
        </div>
      </div>

      <!-- <div class="c-reach-out__email">
        <p class="c-reach-out__email-text">For general queries, drop an email to <span class="c-reach-out__email-text_link" @click="mailTo('support@sparrowexchange.com')">support@sparrowexchange.com</span></p>
      </div> -->
      <TradeNow v-if="!token"></TradeNow>
      <div class="c-reach-out__media">
        <h3 class="c-reach-out__media-text">Follow us on social media:</h3>
        <div class="c-reach-out__media-list" v-for="(dt,index) in socialList" :key="index">
          <img class="c-reach-out__media-list-img" :src="pressedIndex == index ? dt.imgPressed : hoveredIndex == index ? dt.imgHovered : dt.img " @mouseover="hoverImage(index)" @mouseleave="hoverImage(null)" @mousedown="pressImage(index)" @mouseup="pressImage(null)" @click="clickImage(dt.link)"/>
        </div>
        <!-- <img src="@/assets/images/social-youtube.svg" class="c-reach-out__media-list-img" @click="openLink('https://www.youtube.com/channel/UCXW1AX7vdgkcIxNaZJFRFwg')">
        <img src="@/assets/images/social-facebook.svg" class="c-reach-out__media-list-img" @click="openLink('https://www.facebook.com/SparrowExchange')">
        <img src="@/assets/images/social-linkedin.svg" class="c-reach-out__media-list-img" @click="openLink('https://www.facebook.com/SparrowExchange')"> -->

        <!-- <img src="@/assets/images/social-instagram.svg" class="c-reach-out__media-img" @click="openLink('https://www.instagram.com/sparrowexchange/')">
        <img src="@/assets/images/social-telegram.svg" class="c-reach-out__media-img" @click="openLink('https://t.me/SparrowExchange')"> -->
      </div>
    </div>
  </div>
</template>

<script>
  import _debounce from 'lodash.debounce';
  import TradeNow from '@/components/TradeNow.vue'
  export default {
    name: 'ReachOutSparrow',
    components: {
      TradeNow
    },
    data() {
      return {
        name: '',
        company: '',
        contactNumber: '',
        email: '',
        termChecked: false,
        informationChecked: false,
        questionList: [],
        preventSpam: false,
        validEmail: true,
        tradeEmail: '',
        screenWidth: window.innerWidth,
        tradePassword: '',
        hoveredIndex: null,
        pressedIndex: null,
        socialList:[
          {
            img: require('../../assets/images/social-youtube.svg'),
            imgHovered: require('../../assets/images/social-youtube-hover.svg'),
            imgPressed: require('../../assets/images/social-youtube-pressed.svg'),
            link:'https://www.youtube.com/channel/UCXW1AX7vdgkcIxNaZJFRFwg'
          },
          {
            img: require('../../assets/images/social-facebook.svg'),
            imgHovered: require('../../assets/images/social-facebook-hover.svg'),
            imgPressed: require('../../assets/images/social-facebook-pressed.svg'),
            link:'https://www.facebook.com/SparrowExchange'
          },
          {
            img: require('../../assets/images/social-linkedin.svg'),
            imgHovered: require('../../assets/images/social-linkedin-hover.svg'),
            imgPressed: require('../../assets/images/social-linkedin-pressed.svg'),
            link:'https://www.linkedin.com/company/sparrowsg/'
          },
        ]
      }
    },
    mounted() {
      this.getQuestionList()
    },
    methods: {
      hoverImage(idx) {
        this.hoveredIndex = idx
      },
      pressImage(idx) {
        this.pressedIndex = idx
      },
      clickImage(link) {
        this.openLink(link)
      },
      clickTerm() {
        this.termChecked = !this.termChecked
      },
      clickInformation() {
        this.informationChecked = !this.informationChecked
      },
      resetForm() {
        this.name = ""
        this.email = ""
        this.contactNumber = ""
        this.company = ""
        this.termChecked = false
        this.informationChecked = false
      },
      submitData() {
        if(this.termChecked && this.checkFormValue && this.preventSpam == false && this.validEmail) {
          this.preventSpam = true
          let headersApp = {
            'Content-Type': 'application/json'
          };

          let dataContact = {
            "name" : this.name,
            "email" : this.email,
            "phone" : this.contactNumber,
            "companyName" : this.company,
            "subject" : "Financial Institutions and Wealth Managers"
          }

          this.$http.post(this.createApiUrlChestNut('system/contact-us'), dataContact, {headers: headersApp}).then(() => {
            this.preventSpam = false
            this.$eventHub.$emit('show-alert', true, {title: 'Thank you for your query and interest in Sparrow`s digital asset wealth management solutions.', body: 'Our team will be in touch with you shortly.'}, true)
            this.resetForm()
          }, () => {
            this.preventSpam = false
            this.$eventHub.$emit('show-alert', true, {title: 'Oops, something went wrong.', body: 'Please try again.'}, false)
          })
        }
      },
      isNumber: function(evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
          evt.preventDefault();
        } else {
          return true;
        }
      },
      checkEmailValidity: function(){
        var emailReg = /^([\w-.+0-9]+@([\w-]+.)+[\w-]{2,4})?$/;
        this.validEmail = emailReg.test(this.email);
      },
      getQuestionList() {
        let hardCodeQuestion = [
          {
            text: 'Getting started and registering your trading account with Sparrow',
            link: 'https://sparrowexchange.com/signup'
          },
          {
            text: 'How do I resolve bugs and 2FA login error issues',
            link: 'https://support.sparrowexchange.com/hc/en-us/articles/360025827332-How-do-I-replace-my-2FA-retrieval-method-or-remove-the-2FA-requirement'
          },
          {
            text: 'How do I secure and protect my trading account?',
            link: 'https://support.sparrowexchange.com/hc/en-us/articles/360020709392-What-are-the-password-requirement-for-my-Sparrow-account-'
          },
          {
            text: 'Can I find out more about Sparrow Tokens',
            link: 'https://support.sparrowexchange.com/hc/en-us/articles/360028880532-What-is-the-Sparrow-Token-SPO'
          },
          {
            text: 'I want to know more about trading options on cryptocurrencies',
            link: '/consumer/individual'
          },
          {
            text: 'Queries relating to transactions and settlement errors',
            link: 'https://support.sparrowexchange.com/hc/en-us/sections/360003155992-Issues-and-Bugs'
          }
        ]

        this.questionList = hardCodeQuestion
      }
    },
    computed: {
      checkFormValue() {
        if(this.name != '' && this.company != '' && this.contactNumber != '' && this.email!= '' && this.validEmail) {
          return true
        } else {
          return false
        }
      },
      token:function(){
        return this.$store.getters.token;
      },
      debouncedEmailCheck: function(){
        return _debounce(this.checkEmailValidity, 500);
      }
    }
  }
</script>
