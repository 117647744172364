<template>
  <div class="page">
    <div class="c-page o-page-wrapper">
      <Sidebar :status="'joinsparrow'" :elementClass="'.c-suggestion'"></Sidebar>
      <div class="o-sidebar-content">
        <PageBanner :bannerData = "bannerData" :theme="theme"></PageBanner>
        <div class="c-page__content c-page__content_no-bottom-padding">
          <Content></Content>
          <LikeSuggestion></LikeSuggestion>
          <!-- <GetStartedContent></GetStartedContent> -->
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Sidebar from '@/components/Sidebar.vue'
import Footer from '@/components/Footer.vue'
import PageBanner from '@/components/PageBanner.vue'
import Content from '@/components/contactus/ReachOutSparrowContent.vue'
import LikeSuggestion from '@/components/LikeSuggestion.vue'
// import GetStartedContent from '@/components/GetStartedContent.vue'

export default {
  name: 'Institution',
  components: {
    Sidebar,
    Footer,
    PageBanner,
    Content,
    LikeSuggestion,
    // GetStartedContent
  },
  data() {
    return {
      bannerData: [
        {
          img : require('@/assets/images/banner/Sparrow celebrates major milestones.jpg'),
          title:"<span class='c-page-banner__regular c-page-banner__regular-longtext'>Sparrow celebrates</span>",
          subtitle:"<span class='c-page-banner__bold c-page-banner__mobile'>major milestones and <br /> business achievements</span>",
          buttonText:"Find out more >",
          buttonPage:"/about/milestone"
        },
      ],
      theme: 'two',
    }
  },
  mounted:function(){
    this.updateTitleMeta('Sparrow | Find Out More About Our Digital Asset Solutions')
    this.updateDescriptionMeta('Drop Sparrow a query on matters relating to digital asset solutions or getting started on your trading journey and our team will connect with you.')
    this.updateKeywordMeta('Sparrow, wealth management, trading options, cryptocurrencies')
  }
}
</script>
